
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { UserCanceledClass } from "../../models";
import { dateDB_to_date, dayFormat, getCancelledDayFormat, getDayOfWeek, getDaysOfWeekFrom, getWeekNumber } from "../../utils/dateUtils";
import { Auth } from 'aws-amplify';
import { ClassSession, ClassState, UserClassSession, UserExtended, UserState } from '../../types';
import { admin_deleteClassUser, getUser, getUsersSessionCancelled, getUsersSessionJoined, getUsersSessionsCancelledFromUser, getUsersSessionsJoinedFromUser, removeUserSessionJoined } from './goulapi.user';
import { getAvailableClasses, getClasesFromDayOfWeek } from './goulapi.class';
import { disableUser } from '../cognito/cognito.service';
import { baseListGQLRequest, baseGQLRequest } from './goulapi.utils';

export async function getCancelledDays(weekFrom: number = 0, weekTo: number = 999999): Promise<any[]> {
    var variables;
    if (weekFrom == weekTo) {
        variables = { filter: { date: { eq: weekFrom } } };
    }
    else {
        variables = { filter: { date: { between: [weekFrom, weekTo] } } };
    }
    return baseListGQLRequest(variables, queries.listCancelledDays);
}

export async function admin_cancelClassDay(classId: string, date: string, description: string = ""): Promise<any[]> {
    try {
        const fecha = getWeekNumber(new Date(date));

        // Check if the class is cancelled already
        const existingCanceled = await baseListGQLRequest({ filter: { classID: { eq: classId }, date: { eq: fecha } } }, queries.listCanceledClasses) //TODO make the call for classID 

        if (existingCanceled.length > 0) {
            console.log('Class is already cancelled');
            return existingCanceled;
        }

        // Remove all recheluded sessions for the day
        const userJoinedClasses = await baseListGQLRequest({ filter: { classID: { eq: classId }, date: { eq: fecha } } }, queries.listUserJoinedClasses);

        for (const joinedClass of userJoinedClasses || []) {
            removeUserSessionJoined(joinedClass.id);
        }

        // Cancel the class to the user
        const newCanceledClass = {
            classID: classId,
            date: fecha,
            description: description
        }

        return await baseListGQLRequest({ input: newCanceledClass }, mutations.createCanceledClass);

    } catch (error) {
        console.log('Error retrieving createUserCanceledClass', error);
    }
}

export async function admin_cancelDay(date: Date, description: string = "", unavailableDay: boolean = false): Promise<any[]> {
    try {
        const fecha = getWeekNumber(date);
        const cancelledDayFormat = getCancelledDayFormat(date);

        // Check if the class is cancelled already
        const existingCanceled = await getCancelledDays(cancelledDayFormat);

        if (existingCanceled.length > 0) {
            console.log('Class is already cancelled');
            return existingCanceled;
        }

        //Get all classes from that day
        const classes = await getClasesFromDayOfWeek(getDayOfWeek(date));
        for (const classItem of classes) {
            // Remove all recheluded sessions for the day
            const userJoinedClasses = await baseListGQLRequest({ filter: { classID: { eq: classItem.id }, date: { eq: fecha } } }, queries.listUserJoinedClasses);

            for (const joinedClass of userJoinedClasses || []) {
                removeUserSessionJoined(joinedClass.id);
            }
        }

        // Cancel the class to the user
        const newCancelledDay = {
            date: cancelledDayFormat,
            description: description,
            unavailableDay: unavailableDay
        }

        return await baseGQLRequest({ input: newCancelledDay }, mutations.createCancelledDay);

    } catch (error) {
        console.log('Error retrieving admin_cancelDay', error);
    }
}

export async function admin_removeUser(user: UserExtended) {
    if (user?.classes) {
        for (const classItem of user.classes.items) {
            await admin_deleteClassUser(user.id, classItem.class.id);
        }
    }
    disableUser((await Auth.currentSession()).getAccessToken().getJwtToken(), user.userName);
    console.log(`Delete button pressed for ${user.name}`);
}

export async function getClassesFromRange(startDate: Date, endDate: Date, types: string[] = []): Promise<ClassSession[]> {
    const classes: ClassSession[] = [];

    try {
        const weekStart = getWeekNumber(startDate);
        const weekEnd = getWeekNumber(endDate);

        const allClasses = await getAvailableClasses(types);
        const allUsersCancelled = await getUsersSessionCancelled(weekStart, weekEnd);
        const allUsersJoined = await getUsersSessionJoined(weekStart, weekEnd);
        const allCancelledDays = await getCancelledDays(getCancelledDayFormat(startDate), getCancelledDayFormat(endDate));

        for (const classItem of allClasses) {
            const dayOfWeek = classItem.dayOfWeek;
            const daysInMonth = getDaysOfWeekFrom(startDate, endDate, dayOfWeek);
            for (const day of daysInMonth) {
                var state = ClassState.AVAILABLE;
                var canceledDay = allCancelledDays.filter((canDay) => canDay.date == getCancelledDayFormat(new Date(day)));
                var canceledClass = classItem.canceledClasses.items.filter((canDay) => canDay.date == getWeekNumber(new Date(day)))
                if (canceledDay.length > 0) {
                    state = canceledDay[0].unavailableDay
                        ? ClassState.CANCELED_NO_RESCHEDULE
                        : ClassState.CANCELED;
                } else if (canceledClass.length > 0) {
                    state = ClassState.CANCELED;
                }

                const userCancelled = allUsersCancelled.filter((userCan) => userCan.classID == classItem.id && userCan.date == getWeekNumber(new Date(day)));
                const userJoined = allUsersJoined.filter((userJoin) => userJoin.classID == classItem.id && userJoin.date == getWeekNumber(new Date(day)));

                const users = JSON.parse(JSON.stringify(classItem.users.items));
                users.forEach(element => {
                    element['status'] = UserState.REGULAR;
                })
                userCancelled.map((userCan) => {
                    var userwCancelled = users.filter((user) => user.userId === userCan.userID);
                    userwCancelled.forEach(element => {
                        element['status'] = UserState.CANCELED;
                    });
                });

                userJoined.map((userJoin) => {
                    userJoin['status'] = UserState.RESCHEDULED;
                    userJoin['userId'] = userJoin.userID;
                    userJoin['userJoinedClassID'] = userJoin.id;
                    //Problems with camelcasing things
                    users.push(userJoin);
                });

                classes.push({
                    id: classItem.id,
                    description: classItem.description,
                    time: classItem.time,
                    classTypeID: classItem.classTypeID,
                    classType: classItem.classType.name,
                    duration: classItem.duration,
                    maxAssist: classItem.maxAssist,
                    dayOfWeek: dayOfWeek,
                    state: state,
                    users: users,
                    numOfAssist: 0,
                    date: day,
                });
            }
        }
    } catch (error) {
        console.log('Error retrieving createUserCanceledDay', error);
    }

    return classes;
}

export async function getClassesFromUser(userID: string, startDate: Date, endDate: Date): Promise<UserClassSession[]> {
    if (!userID) {
        return [];
    }

    const weekStart = getWeekNumber(startDate);
    const weekEnd = getWeekNumber(endDate);

    try {
        const user = await getUser(userID);

        const allClasses = user?.classes?.items || [];
        const classes: UserClassSession[] = [];
        const allUsersCancelled = await getUsersSessionsCancelledFromUser(userID, weekStart, weekEnd); //Get userCancelledClases from user and fiilter it!
        const allUsersJoined = await getUsersSessionsJoinedFromUser(userID, weekStart, weekEnd);
        const allCancelledDays = await getCancelledDays(getCancelledDayFormat(startDate), getCancelledDayFormat(endDate));


        for (const classItem of allClasses) {
            const classSel = classItem.class;
            const dayOfWeek = classSel.dayOfWeek;
            const daysInMonth = getDaysOfWeekFrom(startDate, endDate, dayOfWeek);

            for (const day of daysInMonth) {
                var state = ClassState.AVAILABLE;
                var canceledDay = allCancelledDays.filter((canDay) => canDay.date == getCancelledDayFormat(new Date(day)));
                var canceledClass = classSel.canceledClasses.items.filter((canDay) => canDay.date == getWeekNumber(new Date(day)))
                // Check if there are any canceled days
                if (canceledDay.length) {
                    // Set state based on whether the day is unavailable or not
                    state = canceledDay[0].unavailableDay ? UserState.CANCELED_NON_RESCHEDULED : UserState.CANCELED;
                }
                // Check if there are any canceled classes when no canceled days are found
                else if (canceledClass.length) {
                    // TODO: Verify if the class cancellation is specific to the day
                    state = UserState.CANCELED;
                }
                classes.push({
                    id: classSel.id,
                    time: classSel.time,
                    classTypeID: classSel.classTypeID,
                    classType: classSel.classType,
                    duration: classSel.duration,
                    maxAssist: classSel.maxAssist,
                    dayOfWeek: dayOfWeek,
                    description: classSel.description,
                    state: state,
                    date: day,
                });
            }
        }

        //TODO: Re-filter the classes because the user can join more classes if they cancell the previous one
        allUsersJoined?.forEach((element) => {
            const date = dateDB_to_date(element.date, element.class.dayOfWeek);
            if (date < startDate || date > endDate) {
                return;
            }
            classes.push({
                id: element.class.id,
                time: element.class.time,
                classTypeID: element.class.classTypeID,
                classType: element.class.classType,
                duration: element.class.duration,
                description: element.class.description,
                maxAssist: element.class.maxAssist,
                dayOfWeek: element.class.dayOfWeek,
                state: UserState.RESCHEDULED,
                userCancelledClass: element.userCanceledClass,
                date: dayFormat(date),
                joinedID: element.id
            })
        })

        allUsersCancelled?.forEach((userCan) => {
            const fClasses = classes.filter((classSel) => {
                return !userCan.isCancelled && (userCan.classID === classSel.id && dayFormat(dateDB_to_date(userCan.date, userCan.class.dayOfWeek)) === classSel.date);
            })
            fClasses.forEach((fClass) => {
                fClass.state = userCan.userJoinedClass.items.length > 0 ? UserState.CANCELED_RESCHEDULED : UserState.CANCELED;
                fClass['canceledClassID'] = userCan.id;
                fClass['userJoinedClass'] = userCan.userJoinedClass.items.length > 0 ? userCan.userJoinedClass.items[0] : null;
            });
        });

        allCancelledDays?.forEach((element) => {
            const date = dateDB_to_date(element.date, element.dayOfWeek);
        })

        return classes;
    } catch (error) {
        console.log('Error retrieving getClassesFromUser:', error);
        return [];
    }
}