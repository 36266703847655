export function dateDB_to_date(date, dayOfWeek)
{
    return new Date(Math.floor(date/100), 0, (date%100 - 1 ) * 7 + (dayOfWeek) - (7 + new Date(Math.floor(date/100), 0, 1).getDay() - 1 ) % 7);
};

export function dateDBCancelledDay_to_date(cancelledDayFormat)
{
    const dayOfweek = cancelledDayFormat%10;
    const date= (cancelledDayFormat - dayOfweek)/10;
    return dateDB_to_date(date, dayOfWeek);
};

export function dayFormat(selectedDate)
{
  return new Date(selectedDate - (new Date(selectedDate).getTimezoneOffset()*60*1000)).toISOString().split('T')[0];;
};

export function getDayOfWeek(date)
{
  return date.getDay();
};

export const getDaysOfWeekFrom = (fechaInicio, fechaFinal, diaSemana) => {
    const resultados = [];
    const diaDeLaSemana = fechaInicio.getDay(); // 0 = domingo, 1 = lunes, ..., 6 = sábado

    const dia = new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), fechaInicio.getDate());
    dia.setDate(dia.getDate() + (7 + diaSemana - diaDeLaSemana) % 7);

    // Añade todos los lunes al arreglo de resultados
    while (dia <= fechaFinal) {
        resultados.push(dayFormat(dia));
        dia.setDate(dia.getDate() + 7);
    }

    return resultados;
};


export const getDaysFrom = (fechaInicio, fechaFinal) => {
    const resultados = [];
    const diaDeLaSemana = fechaInicio.getDay(); // 0 = domingo, 1 = lunes, ..., 6 = sábado

    const dia =  new Date(fechaInicio);

    // Añade todos los lunes al arreglo de resultados
    while (dia <= fechaFinal) {
        resultados[dayFormat(dia)] = [];
        dia.setDate(dia.getDate() + 1);
    }

    return resultados;
};

export const generateTimeArray = (start, end, interval) => {
    const timeArray = [];
    let currentTime = new Date(`2023-01-01 ${start}`);
    const endTime = new Date(`2023-01-01 ${end}`);
  
    while (currentTime <= endTime) {
      timeArray.push(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
      currentTime = new Date(currentTime.getTime() + interval * 60 * 1000);
    }
  
    return timeArray;
}

export function getWeekNumber(date: Date): number {

    const year = date.getFullYear();
  
    const onejan = new Date(year, 0, 1);
    const millisecsInDay = 86400000;
    const week = Math.ceil((((date.getTime() - onejan.getTime()) / millisecsInDay + onejan.getDay() + 1) + (7 + new Date(year, 0, 1).getDay() - 1 ) % 7) / 7);
  
    return year*100 + week;
  }
  
  export function getCancelledDayFormat(date: Date): number {

    const year = date.getFullYear();
  
    const onejan = new Date(year, 0, 1);
    const millisecsInDay = 86400000;
    const week = Math.ceil((((date.getTime() - onejan.getTime()) / millisecsInDay + onejan.getDay() + 1) + (7 + new Date(year, 0, 1).getDay() - 1 ) % 7) / 7);
  
    return (year*100 + week)*10 + getDayOfWeek(date);
  }