import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { Class } from '../../../../../models';
import { Table, TableWrapper, Row, Col, Rows, Cell } from 'react-native-table-component';
import { generateTimeArray } from '../../../../../utils/dateUtils'
//Antd
import Button from '@ant-design/react-native/lib/button';
import ActivityIndicator from '@ant-design/react-native/lib/activity-indicator';

interface ScheduleColumnProps {
    classList: Class[];
    isClassSelectable: boolean;
}

const ScheduleColumn: React.FC<ScheduleColumnProps> = ({ classList = [], isClassSelectable = false }) => {

    const customSort = (a, b) => {
        const [aHours, aMinutes] = a.time.split(/[:.]/).map(Number);
        const [bHours, bMinutes] = b.time.split(/[:.]/).map(Number);

        if (aHours !== bHours) {
            return aHours - bHours;
        }
        return aMinutes - bMinutes;
    };

    function getButton(itemsFound: Class[]) {
        return (
            <><View style={styles.cellView}>
                {
                    itemsFound.map((item: Class) => (
                        <Button
                            disabled={!isClassSelectable}
                            style={{ flex: 1, justifyContent: 'center', margin: 2 }}
                            onPress={() => {
                                //TODO shows modal with a list of the users that are in the class
                                //TODO add buttont to remove the class
                                console.log(item); // Log the itemtoReturn when the button is pressed
                            }}>
                            <Text>
                                <Text style={{ fontWeight: 'bold' }}>{item.classType.name}</Text>{'\n'}
                                <Text style={{ color: '#A9A9A9' }}>{`${item.time} - ${calculateEndTime(item.time, item.duration)}`}</Text>
                            </Text>
                        </Button>
                    ))
                }
            </View></>
        )

    }

    function getTableItem(startTimeStr, timeTable, index, sortedData) {
        if (sortedData.length > 0) {
            var item = sortedData[0];
            var startTime = new Date(`2023-01-01 ${startTimeStr}`);
            var endTime = new Date(`2023-01-01 ${timeTable[index + 1]}`);
            var targetTime = new Date(`2023-01-01 ${item.time}`);
            var classesFound = sortedData
                .filter((data) => data.time >= startTimeStr && data.time < timeTable[index + 1]);
            if (classesFound.length > 0) {
                sortedData.splice(0, classesFound.length - 1);
                return (
                    <View style={{ borderBottomWidth: 1, borderColor: '#C1C0B9' }}><Row
                        data={[getButton(classesFound)]}
                        style={styles.row}
                        textStyle={styles.text}
                    /></View>)
            }
            else {
                return (<View style={{ borderBottomWidth: 1, borderColor: '#C1C0B9' }}><Row
                    data={[""]}
                    style={styles.row}
                    textStyle={styles.text}
                /></View>)
            }
        }
        else {
            return (<View style={{ borderBottomWidth: 1, borderColor: '#C1C0B9' }}><Row
                data={[""]}
                style={styles.row}
                textStyle={styles.text}
            /></View>)
        }
    }

    function getClassColumn() {
        var sortedData = classList.sort(customSort);
        var timeTable = generateTimeArray('8:00', '22:00', 15);

        return (
            timeTable.map((startTimeStr, index) => (
                getTableItem(startTimeStr, timeTable, index, sortedData)
            ))
        );
    }

    return (
        <>
            <View style={styles.container}>
                <Table borderStyle={{ borderWidth: 0, borderColor: '#C1C0B9' }}>
                    {getClassColumn()}
                </Table>
            </View>
        </>)
};

export default ScheduleColumn;

const calculateEndTime = (startTime, duration) => {
    const start = new Date(`2023-01-01 ${startTime}`);
    const endTime = new Date(start.getTime() + duration * 60 * 1000);
    return endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const styles = StyleSheet.create({
    container: { flex: 1, paddingTop: 10, backgroundColor: '#fff' },
    head: { height: 40, backgroundColor: '#808B97' },
    text: { margin: 6, color: 'black', textAlign: 'center' },
    row: { flexDirection: 'row', backgroundColor: '#fff', minHeight: 52 },
    cellView: { flexDirection: 'row', backgroundColor: '#fff'},
});
