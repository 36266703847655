
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { Class} from "../../models";
import { baseListGQLRequest, baseGQLRequest } from './goulapi.utils';

// Classes
export async function getAvailableClasses(types: string[] = []): Promise<any[]> {
    const variables = types.length > 0 ? {
        filter: {
            or: types.map(type => ({ classTypeID: { eq: type } }))
        }
    } : {};
    return baseListGQLRequest(variables, queries.listClasses);
}

export async function getClasesFromDayOfWeek(dayOfWeek):  Promise<any[]>{
    return baseListGQLRequest({ filter: { dayOfWeek: {eq: dayOfWeek} } }, queries.listClasses);
}

export async function getClass(classId: string): Promise<any[]> {
    return baseGQLRequest({ id: classId }, queries.getClass);
}

export async function addClass(newClass: Class): Promise<void> {
    return baseGQLRequest({ input: newClass, }, mutations.createClass);
}

export async function removeClass(classId: string): Promise<void> {
    return baseGQLRequest({ input: { id: classId } }, mutations.deleteClass);
}
export async function updateClass(updatedClass: Class): Promise<void> {
    return baseGQLRequest({ input: updatedClass, }, mutations.updateClass);
}
