import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, StyleSheet, View, Text, ActivityIndicator, Modal } from 'react-native';
import { User, Class, ClassUsers } from '../../../../models';
import { Table, Row, Rows } from 'react-native-table-component';
import Button from '@ant-design/react-native/lib/button';
import { Ionicons } from '@expo/vector-icons'; // Import Ionicons
import EditUserModal from './EditUserModal/EditUserModal';
import { UserExtended } from '../../../../types';
import { disableUser, resendUserEmail } from '../../../../services/cognito/cognito.service';
import { Auth } from 'aws-amplify';
import { FlatList } from "react-native";
import { MaterialIcons } from '@expo/vector-icons';
import ClassTag from './EditUserModal/ClassTag';
import YesNoModal from '../../../../modals/YesNoModal';
import { admin_removeUser } from '../../../../services/goulapi/goulapi.service';

interface BasicTableProps {
  rowNames: string[];
  userList: UserExtended[];
  classList: Class[];
  setToReload: (toReload: boolean) => void;
}

const BasicTable: React.FC<BasicTableProps> = ({ rowNames, userList, classList, setToReload }) => {
  var dayOfWeek = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"];
  const [modalVisible, setModalVisible] = useState(false);
  const [userData, setUserData] = useState<UserExtended>();
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 10;
  const totalPages = Math.ceil(userList.length / userPerPage)

  function formatDataTable(pageSize: number, pageNumber: number) {
    const startIndex = pageSize * pageNumber;
    const endIndex = startIndex + pageSize;
    const paginatedData = userList.slice(startIndex, endIndex);
    var dataTableFormatted = [];
    try {
      paginatedData.map((userListItem, index) => (
        dataTableFormatted.push([
          userListItem.name,
          userListItem.email,
          userListItem.telf,
          getUserClassesFormatted(userListItem),
          <View style={styles.buttonContainer} key={index}>
            {userListItem.status === "CONFIRMED" ? null:<YesNoModal
                            buttonText="re-invitar"
                            buttonStyle={styles.deleteButton}
                            buttonTextStyle={styles.deleteButton}
                            question={"Vas a reenviar la invitacion al usuario " + userListItem?.name + "."}
                            confirmationMessage="¿Deseas continuar?"
                            handleYes={() => handleResendEmail(userListItem)}
                            handleNo={()=>{}}
                        />}
          </View>,
          <View style={styles.buttonContainer} key={index}>
            <Button style={styles.editButton} onPress={() => handleEditPress(userListItem)}>
              <Ionicons name="pencil-sharp" size={17} color="white" />
            </Button>
            <View>
            <YesNoModal
                buttonText="Borrar"
                buttonStyle={styles.deleteButton}
                buttonTextStyle={styles.deleteButton}
                question={"Vas a eliminar al usuario " + userListItem?.name + "."}
                confirmationMessage="¿Deseas continuar?"
                handleYes={() => handleDeletePress(userListItem)}
                handleNo={()=>{}}
              />
            </View>
          </View>
        ])
      ));
    } catch (err) {
      console.log(err);
      throw err;
    }

    return dataTableFormatted;
  }

  useEffect(() => {
    if(userData!=null){
      setUserData(userList.find((user) => user.userName === userData.userName));
    }
  }, [userList]); 

  function handleEditPress(userListItem: UserExtended) {
    setUserData(userListItem);
    setModalVisible(true);
  }

  async function handleResendEmail(userListItem: UserExtended) {
    const response = resendUserEmail((await Auth.currentSession()).getAccessToken().getJwtToken(), userListItem.name, userListItem.email);
  }
  async function handleDeletePress(userListItem: UserExtended) {
    // Add your 'delete' button press logic here
    //TODO add delete user clases user etc.
    await admin_removeUser(userListItem);
    setToReload(true);
  }

  function getUserClassesFormatted(user: UserExtended) {
    const classes = user?.classes?.items || []
    return (classes.map((classSel: ClassUsers, key) => {
      return (<ClassTag onDelete={null} tagClass={classSel} isCloseable={false} key={key}></ClassTag>);
    }));
  }

  return (<>
    <EditUserModal 
      visible={modalVisible} 
      setVisible={setModalVisible} 
      onClose={() => {setModalVisible(false);}} 
      userData={userData} 
      setUserData={setUserData}
      classList={classList}
      setToReload={setToReload}/>
    <View style={styles.pagination}>
      <TouchableOpacity onPress={() => setPageNumber((pageNumber + totalPages - 1) % (totalPages))}>
        <MaterialIcons name="navigate-before" size={24} color="black" />
      </TouchableOpacity>
      <Text> {pageNumber + 1} / {totalPages}</Text>
      <TouchableOpacity onPress={() => setPageNumber((pageNumber + 1) % (totalPages))}>
        <MaterialIcons name="navigate-next" size={24} color="black" />
      </TouchableOpacity>
    </View>
    <View style={styles.tableContainer}>
      <FlatList
        data={formatDataTable(userPerPage, pageNumber)} // Set the page size and initial page number
        renderItem={({ item }) => (
          <Table>
            <Row data={item} style={styles.row} textStyle={styles.cellText} flexArr={[2, 2, 2, 3, 1]} />
          </Table>
        )}
        keyExtractor={(item, index) => index.toString()}
        ListHeaderComponent={() => (
          <View style={styles.headerContainer}>
            <Table>
              <Row data={rowNames} style={styles.header} textStyle={styles.headerText} flexArr={[2, 2, 2, 3, 1]} />
            </Table>
          </View>
        )}
      />
    </View>
  </>
  );
};
export default BasicTable;

const styles = StyleSheet.create({
  tableContainer: {
    borderBottomWidth: 2,
    borderColor: '#EC7063',
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderColor: '#EC7063'
  },
  header: {
    backgroundColor: '#FEF3F3',
    height: 40,
    borderWidth: 0
  },
  headerText: {
    fontSize: 15,
    color: '#FF7777',
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: 10,
    borderBottomColor: '#CE9595'
  },
  row: {
    fontSize: 13,
    flexWrap: 'wrap',
    borderBottomWidth: 1,
    borderBottomColor: '#F0E0DC'
  },
  cellText: {
    color: 'black',
    paddingLeft: 7,
    padding: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    backgroundColor: '#55a630',
    borderRadius: 100,
    padding: 5,
    margin: 2,
  },
  deleteButton: {
    backgroundColor: '#ff6961',
    borderRadius: 100,
    padding: 5,
    margin: 2,
  },
  pagination: {
    flexDirection: 'row',
    marginBottom: 5,
  }
});
