import React from 'react'
import { Button, View, Text, StyleSheet} from 'react-native';
import Constants from 'expo-constants'
import theme from '../theme'
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import AdminUserList from '../views/admin/users/AdminUserList'
import AdminClassSchedule from '../views/admin/schedule/AdminClassSchedule';
import AdminCalendarView from '../views/admin/calendar/AdminCalendarView';
import UserCalendarNavigator from '../views/user/userCalendar/UserCalendarNavigator'
import { useAuthenticator } from '@aws-amplify/ui-react-native'
import ClassTypeView from '../views/dev/classType/classTypeView';
import ReSendEmail from '../views/dev/reSendEmail/ReSendEmail';
import { LoadingProvider } from './LoadingContext';

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.appBar.primary,
    flexDirection: 'row',
    paddingTop: Constants.statusBarHeight + 10
  },
  scroll: {
    paddingBottom: 15
  },
  text: {
    color: theme.appBar.textSecondary,
    paddingHorizontal: 10
  },
  active: {
    color: theme.appBar.textPrimary
  }
});


// Aquí está la función SignOut, pero ahora es un componente que muestra un botón
const LogoutScreen = () => {
  const { signOut } = useAuthenticator();

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>¿Estás seguro de que deseas cerrar sesión?</Text>
      <Button title="Cerrar sesión" onPress={signOut} />
    </View>
  );
};

const Drawer = createDrawerNavigator();
const AppBar = () => {
  const { user, } = useAuthenticator((context) => [context.user]);
  const isAdmin = user["signInUserSession"]["idToken"]["payload"]["cognito:groups"]?.includes('admin');
  const isDev = user["signInUserSession"]["idToken"]["payload"]["cognito:groups"]?.includes('dev');

  return (
    <LoadingProvider>
      <NavigationContainer>
        <Drawer.Navigator>
          {isAdmin ? (
            <>
              <Drawer.Screen name="Calendario" component={AdminCalendarView} />
              <Drawer.Screen name="Usuarios" component={AdminUserList} />
              <Drawer.Screen name="Clases" component={AdminClassSchedule} />
            </>
          ) : (
            <>
              <Drawer.Screen name="Mis Clases" component={UserCalendarNavigator} />
            </>
          )}
          {isDev ? (
            <>
              <Drawer.Screen name="ReSend Email" component={ReSendEmail} />
              <Drawer.Screen name="Create class Type" component={ClassTypeView} />
            </>
          ) : (
            <></>
          )}
          <Drawer.Screen name="Logout" component={LogoutScreen} />
        </Drawer.Navigator>
      </NavigationContainer>
    </LoadingProvider>
  )
}

export default AppBar