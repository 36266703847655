import React, { useContext, useState, useEffect } from 'react';
import { View, SafeAreaView, StyleSheet } from 'react-native';
import AddButton from './components/AddButton';
import BasicTable from './components/BasicTable';
import { Class } from '../../../models';
import { UserExtended } from '../../../types';
import { getAvailableClasses } from '../../../services/goulapi/goulapi.class';
import { getUsersAndInfo } from '../../../services/goulapi/goulapi.user';
import { createUser } from '../../../services/cognito/cognito.service';
import { Auth } from 'aws-amplify';
import { LoadingContext } from '../../../components/LoadingContext';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    padding: 20,
    margin: 5,
    minWidth: 1200
  },
  buttonView: {
    marginTop: 10,
    marginBottom: 2,
    margin: 10,
    alignItems: 'flex-end',
  },
  tableView: {
    marginTop: 2,
    marginBottom: 10,
    margin: 10
  },
  loadingModal: {
    width: '80%',
    maxHeight: '80%',
    padding: 20,
    borderRadius: 10,
    overflow: 'hidden',
    alignContent: 'center'
  }
});

function AdminUserList() {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [toReload, setToReload] = useState(false);
  const [userList, setUserList] = useState<UserExtended[]>([]);
  const [classList, setClassList] = useState<Class[]>([]);
  const [token, setToken] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(true);

  async function setTableValues() {
    await setUserList(await getUsersAndInfo());
    await setClassList(await getAvailableClasses());
    await setIsLoading(false);
    await setToReload(false);
  }

  async function firstLoad() {
    await setToken((await Auth.currentSession()).getAccessToken().getJwtToken());
    await setIsFirstTime(false);
    await setToReload(true);
  }

  useEffect(() => {
    if (isFirstTime) {
      setIsLoading(true);
      firstLoad();
    }
  }, [isFirstTime]);

  useEffect(() => {
    if (!isFirstTime && toReload) {
      setIsLoading(true);
      setTableValues();
    }
  }, [toReload]);


  return (
    <SafeAreaView style={styles.container}>
      {toReload ? null :
        <>
        <View style={styles.buttonView}>
          <AddButton buttonText='+ Añadir' onSave={(email: string, name: string) => { createUser(token, name, email).then(() => setToReload(true)); } }></AddButton>
        </View><View style={styles.tableView}>
          <BasicTable rowNames={['NOMBRE', 'E-MAIL', 'TELEFONO', 'CLASES', '']} setToReload={setToReload} userList={userList} classList={classList} />
        </View>
        </>
      }
    </SafeAreaView>
  );
}

export default AdminUserList;