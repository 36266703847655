import React, { useState, useEffect } from 'react';
import { StyleSheet, Button, ActivityIndicator, View, Modal, Text, TouchableOpacity } from 'react-native';
import { CalendarList } from 'react-native-calendars';
import { useNavigation, RouteProp, useRoute } from '@react-navigation/native';
import { dayFormat } from '../../../utils/dateUtils'
import { useAuthenticator } from '@aws-amplify/ui-react-native';
import { Class } from '../../../models/index';
import { getClassesFromRange } from '../../../services/goulapi/goulapi.service';
import { setClassSessionToAgendaFormat, setClassSessionToCalendarFormat } from '../../../utils/agendaUtils';
import { createUserCanceledClass, createUserJoinedClass } from '../../../services/goulapi/goulapi.user';
import { ClassSession, ClassState, UserClassSession, UserState } from '../../../types';
import { Mutex } from 'async-mutex';

import { useContext } from 'react';
import { LoadingContext } from '../../../components/LoadingContext';

const userSelector = (context) => [context.user]
// Define the route params type
type MyScreenParams = {
    classSel: Class;
};

const CustomCalendar: React.FC<CustomCalendarProps> = () => {
    const navigation = useNavigation();
    const route = useRoute<RouteProp<MyScreenParams, 'classSel'>>();

    const { user, signOut } = useAuthenticator(userSelector);
    const [availableClasses, setAvailableClasses] = useState({})
    const [markedClasses, setMarkedClasses] = useState({})
    const [toReload, setToReload] = useState(true);
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    // Access the params
    const today = new Date();
    const classSel = route.params.classSel;
    const userID = route.params.userID;
    const setToReloadFather = route.params.setToReload;

    var daySelStart = new Date(classSel.day);
    daySelStart.setDate(daySelStart.getDate() - 28);

    if(daySelStart < today)
    {
        daySelStart = today;
    }
    
    const daySelEnd = new Date(classSel.day);
    daySelEnd.setDate(daySelEnd.getDate() + 28);

    const [selectedDate, setSelectedDate] = useState(today);
    const [modalVisible, setModalVisible] = useState(false);
    const [newClass, setNewClass] = useState<Class>(null);

    const mutex = new Mutex();

    useEffect(() => {
        async function setAllClasses() {
            if (toReload) {
                setIsLoading(true)
                const classesFromRange = await getClassesFromRange(daySelStart, daySelEnd, [classSel.classTypeID]);

                const classesToAgendaFormat = await setClassSessionToAgendaFormat(daySelStart, daySelEnd, classesFromRange);
                setAvailableClasses(classesToAgendaFormat);
                setMarkedClasses(setClassSessionToCalendarFormat(dayFormat(daySelStart), classesToAgendaFormat));     
                setIsLoading(false)
                setToReload(false)
            }
        }
        setAllClasses();
    }, [toReload]);

    function isJoineabled(clase) {
        const dateTime = new Date(`${clase.day}T${clase.time}`);
        dateTime.setHours(dateTime.getHours());
        const isFull = clase.users.filter((f_user) => f_user.status < UserState.CANCELED_RESCHEDULED).length >= clase.maxAssist;
        const isMyClassDay = isMyClass(clase) ? classSel.day == clase.day && classSel.time == clase.time : true;
        
        return (clase.state == ClassState.AVAILABLE) && (today <= dateTime) && !isFull && isMyClassDay;
    }

    function isMyClass(clase) {       
        return classSel.classID == clase.classID;
    }

    function isMyClassJoineable(clase) {       
        return isMyClass(clase) && classSel.day == clase.day && classSel.time == clase.time;
    }

    function userOnClass(clase) {
        const userOnClass = clase.users.filter((f_user) => {
            if(f_user.userId === user.username) {
                return f_user.status < UserState.CANCELED_RESCHEDULED;
            }
        });
        return userOnClass.length !== 0;
    }

    //TODO move customSort to utils
    const customSort = (a, b) => {
        const [aHours, aMinutes] = a.time.split(/[:.]/).map(Number);
        const [bHours, bMinutes] = b.time.split(/[:.]/).map(Number);

        if (aHours !== bHours) {
            return aHours - bHours;
        }
        return aMinutes - bMinutes;
    };


    function getDailyActivities() {
        var currentDate = dayFormat(selectedDate);
        return (
            <>
                {availableClasses[currentDate]?.sort(customSort).map((clase: Class) => {
                        return (<>{(!isMyClassJoineable(clase) && isMyClass(clase) ? <></> : 
                            <Button
                                key={clase.description + " - " + clase.time}
                                title={clase.description + " - " + clase.time + " (" + clase.users.filter((user) => user.status < UserState.CANCELED_RESCHEDULED).length + "/" + clase.maxAssist + ")" + ((clase.state == ClassState.CANCELED_NO_RESCHEDULE) ? " (Vacaciones)" : (clase.state == ClassState.CANCELED) ? " (Cancelada)" :isMyClassJoineable(clase) ? " (Volver a unirse)" : userOnClass(clase) ? " (En clase)" : "")}
                                onPress={() => { setNewClass(clase); setModalVisible(true) }}
                                disabled={ !( !userOnClass(clase) && isJoineabled(clase)) }
                            />)}</>)
                })}
            </>);
    }

    async function modifySessionDay(oldSession: UserClassSession, newSession: UserClassSession) {
        await mutex.acquire();
        try {
            var canceledClassID;
            if(!oldSession.canceledClassID)
            {
                const canceledClass = await createUserCanceledClass(userID, oldSession.classID, oldSession.day);
                canceledClassID = canceledClass.id;
            }
            else
            {
                canceledClassID = oldSession.canceledClassID;
            }

            await createUserJoinedClass(canceledClassID, userID, newSession.classID, newSession.day);
        } finally {
            setToReloadFather(true);
            mutex.release();
        }
    }

    return (
        <>
            <View style={styles.container}>
                {isLoading ?
                    <ActivityIndicator size="large" color="#0000ff" />
                    :
                    <CalendarList
                        pastScrollRange={0}
                        futureScrollRange={1}
                        firstDay={1} //Start on Modnay
                        current={dayFormat(daySelStart)}
                        minDate={dayFormat(daySelStart)}
                        maxDate={dayFormat(daySelEnd)}
                        markedDates={markedClasses}
                        onDayPress={(day) => {
                            setSelectedDate(new Date(day.dateString));
                            setMarkedClasses(setClassSessionToCalendarFormat(dayFormat(new Date(day.dateString)), availableClasses));
                        }}
                    />
                }
            </View>
            <View style={styles.container}>
                {getDailyActivities()}
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(!modalVisible);
                    }}>
                    <TouchableOpacity
                        style={styles.modalOverlay}
                        activeOpacity={1}
                        onPressOut={() => {
                            setModalVisible(false);
                        }}
                    >
                        <View style={styles.modalView}>
                            <Text style={styles.modalText}> Deseas cambiar tu session por la sesion seleccionada? </Text>
                            <View style={styles.buttonColumnContainer}>
                                <Button title="Aceptar" onPress={() => {
                                    setModalVisible(false);
                                    modifySessionDay(classSel, newClass);
                                    navigation.goBack();
                                }
                                } />
                                <View style={styles.gap} />
                                <Button title="Cancelar" color="#F87575" onPress={() => setModalVisible(false)} />
                            </View>
                        </View>
                    </TouchableOpacity>
                </Modal>
            </View>
        </>
    );

}

export default CustomCalendar;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        margin: 24
    },
    modalView: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalText: {
        color: 'black',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalOverlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonColumnContainer: {
        flexDirection: 'row', // Arrange children horizontally
        justifyContent: 'space-between', // Space evenly between children
        paddingHorizontal: 16, // Optional padding for buttons
    },
    gap: {
        width: 16, // Adjust the width for the desired gap size
    },
});

export interface CustomCalendarProps {
    classTypeId: string;
    previousClassDay: string;
}