import React from 'react'
import { View } from 'react-native'
import Constants from 'expo-constants'
import AppBar from './components/AppBar'



const Main = () => {
  return (
      <View style={{marginTop: Constants.statusBarHeight, flexGrow: 1}}>
        <AppBar />
      </View>
  )
}

export default Main
